import React, { FC, useEffect } from "react"
import { navigate } from "gatsby"
import { gql, useQuery } from "@apollo/client"
import { RouteComponentProps } from "@reach/router"
import { useWallet } from "@gorilla-technologies/providers"
import {
  Button,
  Heading,
  Paragraph,
  Link,
  Stepper,
  ShopBuilderLayout,
} from "../components"
import Illustration from "../images/shop-builder/muzz.svg"
import IllustrationMobile from "../images/shop-builder/muzz-mobile.svg"
import { FaInfoCircle } from "react-icons/fa"

const GET_USER_OWNED_TOKENS = gql`
  query GetOwnedTokens($ownerId: String!) @cached(ttl: 120) {
    tokens: mb_views_nft_tokens(
      where: { owner: { _eq: $ownerId }, burned_timestamp: { _is_null: true } }
    ) {
      metadata_id
    }
  }
`

// We use two different shops as reference shops for NFT access tokens to our product.
// This based on the current NEAR network we're working with (mainnet/testnet)
const isMainnet = process.env.GATSBY_PAGE_BUILDER_NEAR_NETWORK === "mainnet"
const MUZZ_STORE_ID = isMainnet
  ? "bananafratclub.mintbase1.near"
  : "bananafratclub.mintspace2.testnet"

export const MuzzToken: FC<RouteComponentProps> = () => {
  const { wallet, isLoading } = useWallet()
  const isConnected = wallet?.isConnected()
  const ownerId = wallet?.activeAccount?.accountId

  const {
    loading,
    error,
    data: graphqlData,
  } = useQuery(GET_USER_OWNED_TOKENS, {
    variables: { ownerId },
    notifyOnNetworkStatusChange: true,
    skip: !ownerId,
  })

  if (error) console.error(error.message)

  useEffect(() => {
    if (!loading && !isLoading) {
      if (isConnected) {
        if (graphqlData) {
          const ownsMuzzToken = Boolean(
            graphqlData.tokens.find(
              ({ metadata_id }: { metadata_id: string }) =>
                metadata_id.startsWith(MUZZ_STORE_ID)
            )
          )

          if (ownsMuzzToken) {
            // Redirect user to next page if he is logged in
            navigate("/email")
          }
        }
      } else {
        // If the user isn't logged in we redirect him to the login page
        navigate("/login")
      }
    }
  }, [loading, isLoading, graphqlData, isConnected])

  if (isLoading || loading) return null

  return (
    <ShopBuilderLayout
      backgroundColor="#E1FBF2"
      decoration={
        <Illustration className="max-h-[200px] max-w-[200px] md:max-h-full md:max-w-full" />
      }
    >
      <Stepper steps={6} activeStep={2} />
      <div className="flex flex-col gap-6">
        <IllustrationMobile className="md:hidden" />
        <Heading level={2}>Do you own a Muzz token?</Heading>
        <Paragraph level={3}>This is how you pay for our product</Paragraph>
      </div>
      <Button
        title="Yes, I bought one!"
        onClick={() => {
          window.location.reload()
        }}
      />
      <div className="max-w-sm">
        <Paragraph level={3} align="center">
          or
        </Paragraph>
      </div>
      <Button
        title={isLoading ? "Loading..." : "Not yet, go to our Muzz shop"}
        disabled={isLoading}
        onClick={() => {
          const url = isMainnet
            ? "https://bananafratclub.io/"
            : "https://bananafratclub-testnet.netlify.app"
          window.open(url, "_blank")
        }}
      />
      <Link href="https://medium.com/@gorillashops/how-to-set-up-your-own-nft-shop-810685bf491d">
        Why do I need a Muzz token?
      </Link>
      {/* Blockchain Timing info message */}
      <p className="flex items-start justify-start gap-2 rounded-md border-2 border-yellow-600 bg-yellow-100 px-3 py-1.5 text-yellow-900">
        <FaInfoCircle className="mt-1 w-10" />
        If you just bought a token, it might take a while on the blockchain to
        process your purchase. In this case, please try again later.
      </p>
    </ShopBuilderLayout>
  )
}
