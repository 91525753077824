import React, { FC, useState } from "react"
import { navigate } from "gatsby"
import { RouteComponentProps } from "@reach/router"
import { useWallet } from "@gorilla-technologies/providers"
import { useTabCloseWarning } from "../hooks"
import { useFormData, FormData } from "../form-data-context"
import {
  Heading,
  Paragraph,
  Button,
  Stepper,
  Link,
  Checkbox,
  ShopBuilderLayout,
} from "../components"
import { startBuild } from "../services"
import Illustration from "../images/shop-builder/start-build.svg"
import IllustrationMobile from "../images/shop-builder/start-build-mobile.svg"

function isFormDataComplete(formData: FormData) {
  const { shop, email } = formData
  return Boolean(shop && email)
}

export const StartBuild: FC<RouteComponentProps> = () => {
  const { wallet } = useWallet()
  const [formData] = useFormData()
  const [acceptsTos, setAcceptsTos] = useState(false)
  const [buildHasStarted, setbuildHasStarted] = useState(false)

  const { email, shop } = formData
  const accountId = wallet?.activeAccount?.accountId

  useTabCloseWarning()

  const onClick = async () => {
    if (!shop || !email) return

    setbuildHasStarted(true)

    // Trigger build
    try {
      await startBuild(email, shop)
    } catch (e) {
      if (e instanceof Error) console.error(e.message)
    }

    navigate("/building")
  }

  const buildCanBeStarted =
    accountId && isFormDataComplete(formData) && acceptsTos && !buildHasStarted

  return (
    <ShopBuilderLayout
      backgroundColor="#FCFBCF"
      decoration={
        <Illustration className="max-h-[200px] max-w-[200px] md:max-h-full md:max-w-full" />
      }
    >
      <Stepper steps={4} activeStep={4} />
      <div className="flex flex-col gap-6">
        <IllustrationMobile className="md:hidden" />
        <Heading level={2}>
          Ready to build your{" "}
          <span className="text-gorilla-purple">{shop?.name}</span> shop page?
        </Heading>
        <Paragraph level={3}>
          This may take few minutes, we will send you an email when the shop is
          ready.
        </Paragraph>
      </div>
      <Checkbox
        name="tos"
        checked={acceptsTos}
        onChange={() => {
          setAcceptsTos(!acceptsTos)
        }}
        label={
          <>
            I accept the{" "}
            <Link href="/terms-of-service" target="_blank">
              Terms of Service
            </Link>
          </>
        }
      />
      <Button
        title="Start building the shop"
        onClick={onClick}
        disabled={!buildCanBeStarted}
      />
    </ShopBuilderLayout>
  )
}
