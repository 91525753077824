import React, { useState, useEffect } from "react"
import { gql, useQuery } from "@apollo/client"

type Shop = {
  name: string
  id: string
}

const GET_MINTBASE_SHOPS = gql`
  query GetMintbaseShops @cached(ttl: 120) {
    nft_contracts(where: { is_mintbase: { _eq: true } }) {
      name
      id
    }
  }
`

export function useMintbaseShops(): Shop[] {
  const [shops, setShops] = useState<Shop[]>([])
  const { error, data } = useQuery(GET_MINTBASE_SHOPS, {
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    if (error) console.error(error.message)
    if (data) {
      const shops = data.nft_contracts
      const sortedShops = [...shops].sort((a: Shop, b: Shop) =>
        a.name > b.name ? 1 : -1
      )

      setShops(sortedShops)
    }
  }, [data, error])

  return shops
}
