import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react"

export interface FormData {
  shop?: {
    id: string
    name: string
  }
  email?: string
}

type FormDataContextType = [
  formData: FormData,
  setFormData: Dispatch<SetStateAction<FormData>>
]

const FormDataContext = createContext<FormDataContextType>([{}, () => {}])

function useFormData() {
  const context = useContext(FormDataContext)
  if (context === undefined) {
    throw new Error(`useCount must be used within a FormProvider`)
  }
  return context
}

function FormDataProvider(props: any) {
  const [formData, setFormData] = useState<FormData>()
  // eslint-disable-next-line
  return <FormDataContext.Provider value={[formData, setFormData]} {...props} />
}

export { FormDataProvider, useFormData }
