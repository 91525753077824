import React, { FC, useEffect } from "react"
import { navigate } from "gatsby"
import { RouteComponentProps } from "@reach/router"
import { useWallet } from "@gorilla-technologies/providers"
import {
  Button,
  Heading,
  Paragraph,
  Link,
  Stepper,
  ShopBuilderLayout,
} from "../components"
import Illustration from "../images/shop-builder/login.svg"
import IllustrationMobile from "../images/shop-builder/login-mobile.svg"

const isMainnet = process.env.GATSBY_PAGE_BUILDER_NEAR_NETWORK === "mainnet"

export const Login: FC<RouteComponentProps> = () => {
  const { isLoading, wallet } = useWallet()

  useEffect(() => {
    if (wallet?.isConnected()) {
      // Redirect user to next page if he is logged in
      navigate("/email")
    }
  }, [wallet])

  if (isLoading) return null

  return (
    <ShopBuilderLayout
      backgroundColor="#FDE9E9"
      decoration={
        <Illustration className="max-h-[200px] max-w-[200px] md:max-h-full md:max-w-full" />
      }
    >
      <Stepper steps={4} activeStep={1} />
      <div className="flex flex-col gap-6">
        <IllustrationMobile className="md:hidden" />
        <Heading level={2}>Login with your NEAR wallet</Heading>
        <Paragraph level={3}>
          To create a shop on GorillaShops you need to have a NEAR wallet, a{" "}
          <Link
            href={
              isMainnet
                ? "https://mintbase.xyz"
                : "https://testnet.mintbase.xyz"
            }
          >
            Mintbase
          </Link>{" "}
          shop and a Muzz token. To learn more about this read our{" "}
          <Link href="https://medium.com/@gorillashops/how-to-set-up-your-own-nft-shop-810685bf491d">
            blog post
          </Link>
        </Paragraph>
      </div>
      <Button
        title="Log In with NEAR"
        onClick={async () => {
          await wallet?.connect({ requestSignIn: true })
        }}
        disabled={isLoading}
        icon="near"
      />
      <Link
        href={
          isMainnet
            ? "https://wallet.near.org/"
            : "https://wallet.testnet.near.org/"
        }
      >
        Get a NEAR Wallet
      </Link>
    </ShopBuilderLayout>
  )
}
