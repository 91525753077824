import { useEffect } from "react"

// Warn the user before closing the tab
export const useTabCloseWarning = () => {
  useEffect(() => {
    function preventWindowClose(e: any) {
      e.preventDefault()
      e.returnValue = ""
    }

    window.addEventListener("beforeunload", preventWindowClose)

    // Clean up if component unmounts
    return () => {
      window.removeEventListener("beforeunload", preventWindowClose)
    }
  }, [])
}
