export async function startBuild(
  email: string,
  shop: {
    id: string
    name: string
  }
): Promise<any> {
  const body = {
    email,
    mintbaseShop: {
      ...shop,
      name: `gorillashops-${shop.name}`,
    },
  }

  // Default options are marked with *
  const response = await fetch(process.env.GATSBY_PAGE_BUILDER_API as string, {
    method: "POST",
    body: JSON.stringify(body),
  })

  if (!response.ok) throw Error(response.statusText)
}
