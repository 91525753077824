import React, {
  FC,
  useEffect,
  useState,
  useRef,
  KeyboardEventHandler,
} from "react"
import { navigate } from "gatsby"
import { RouteComponentProps } from "@reach/router"
import { FaShieldAlt } from "react-icons/fa"
import { useFormData } from "../form-data-context"
import {
  Button,
  Heading,
  Paragraph,
  Input,
  Stepper,
  ShopBuilderLayout,
} from "../components"
import Illustration from "../images/shop-builder/email.svg"
import IllustrationMobile from "../images/shop-builder/email-mobile.svg"

// Source: https://ui.dev/validate-email-address-javascript
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const Email: FC<RouteComponentProps> = () => {
  const [formData, setFormData] = useFormData()

  const [email, setEmail] = useState("")
  const [repeatedEmail, setRepeatedEmail] = useState("")

  const emailInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    // Focus email input
    if (emailInput.current) emailInput.current.focus()
  }, [])

  const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === "Enter") {
      onFormSubmit()
    }
  }

  const isValidEmail = emailRegex.test(email)
  const isValidRepeatedEmail = isValidEmail && email === repeatedEmail
  const isValidForm = isValidEmail && isValidRepeatedEmail
  const onFormSubmit = () => {
    if (!isValidForm) return

    setFormData({
      ...formData,
      email,
    })

    navigate("/shop-selection")
  }

  return (
    <ShopBuilderLayout
      backgroundColor="#D4E3FD"
      decoration={
        <Illustration className="max-h-[200px] max-w-[200px] md:max-h-full md:max-w-full" />
      }
    >
      <Stepper steps={4} activeStep={2} />
      <div className="flex flex-col gap-6">
        <IllustrationMobile className="md:hidden" />
        <Heading level={2}>What&apos;s your email address?</Heading>
        <Paragraph level={3}>
          We need your email address so that we can send the login credentials
          of your site to you
        </Paragraph>
      </div>
      <div className="flex flex-col gap-6">
        <Input
          value={email}
          label="Email address"
          placeholder="Enter your Email address"
          type="email"
          onChange={e => {
            setEmail(e.target.value)
          }}
          ref={emailInput}
          onKeyUp={handleKeyUp}
          invalid={!isValidEmail}
        />
        <Input
          value={repeatedEmail}
          label="Repeat Email address"
          placeholder="Re-enter your Email address"
          type="email"
          onChange={e => {
            setRepeatedEmail(e.target.value)
          }}
          onKeyUp={handleKeyUp}
          invalid={Boolean(repeatedEmail) && !isValidRepeatedEmail}
        />
      </div>
      <Button title="Next" onClick={onFormSubmit} disabled={!isValidForm} />
      <Paragraph level={3}>
        <FaShieldAlt className="inline-block h-3 w-3 align-baseline" /> We
        won&apos;t store your email address.
      </Paragraph>
    </ShopBuilderLayout>
  )
}
