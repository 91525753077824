import React, { useEffect } from "react"
import { Router, Redirect } from "@reach/router"
import { Helmet } from "react-helmet"
import {
  WalletProvider,
  MintbaseApolloProvider,
  IWalletProvider,
} from "@gorilla-technologies/providers"
import Logo from "../images/logo.svg"
import {
  Login,
  Email,
  ShopSelection,
  StartBuild,
  Building,
  NotFound,
} from "../shop-builder-pages"
import { FormDataProvider } from "../form-data-context"
import "../css/create-shop.css"
import classNames from "classnames"

const isTestnet = process.env.GATSBY_PAGE_BUILDER_NEAR_NETWORK !== "mainnet"
const TESTNET_URL = "https://interop-testnet.hasura.app/v1/graphql"
const MAINNET_URL = "https://interop-mainnet.hasura.app/v1/graphql"

const CreateShopPage = () => {
  const logoWrapperClassName = classNames({
    "absolute left-5 flex justify-center lg:left-14": true,
    "top-24": isTestnet,
    "top-12": !isTestnet,
  })

  return (
    <MintbaseApolloProvider uri={isTestnet ? TESTNET_URL : MAINNET_URL}>
      <WalletProvider
        apiKey={process.env.GATSBY_MINTBASE_API_KEY ?? ""}
        network={
          process.env
            .GATSBY_PAGE_BUILDER_NEAR_NETWORK as IWalletProvider["network"]
        }
      >
        <FormDataProvider>
          <Helmet title="Bazaar | Gorillashops" />
          <div className="bg-pale-blue-gray relative min-h-screen">
            {/* Using an a tag here to trigger an (preventable) page-leaving event */}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://gorillashops.io"
              className={logoWrapperClassName}
            >
              <Logo className="h-8 w-auto md:h-10" />
            </a>
            <Router basepath="/" className="min-h-screen">
              <Login path="/login" />
              <Email path="/email" />
              <ShopSelection path="/shop-selection" />
              <StartBuild path="/start-build" />
              <Building path="/building" />
              <Redirect from="/" to="/login" noThrow />
              <NotFound default />
            </Router>
          </div>
        </FormDataProvider>
      </WalletProvider>
    </MintbaseApolloProvider>
  )
}

export default CreateShopPage
