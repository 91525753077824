import React, { FC } from "react"
import { RouteComponentProps } from "@reach/router"
import { CgSpinner } from "react-icons/cg"

export const NotFound: FC<RouteComponentProps> = () => {
  return (
    <div className="flex flex-col items-center gap-6 pt-40 sm:gap-12 sm:pt-80">
      <CgSpinner className="text-gorilla-purple animate-spin text-6xl" />
    </div>
  )
}
