import React, { useEffect, useRef, FC } from "react"
import { navigate } from "gatsby"
import { RouteComponentProps } from "@reach/router"
import { useFormData } from "../form-data-context"
import {
  Heading,
  Button,
  Link,
  Stepper,
  ShopBuilderLayout,
  Combobox,
} from "../components"
import { useTabCloseWarning, useMintbaseShops } from "../hooks"
import Illustration from "../images/shop-builder/shops.svg"
import IllustrationMobile from "../images/shop-builder/shops-mobile.svg"

type Shop = {
  id: string
  name: string
}

export const ShopSelection: FC<RouteComponentProps> = () => {
  const [formData, setFormData] = useFormData()
  const { shop } = formData

  const shops = useMintbaseShops()

  // Determine which createShopLink to use, based on env
  const createShopLinkMainnet = "https://www.mintbase.xyz"
  const createShopLinkTestnet = "https://testnet.mintbase.xyz"
  const createShopLink =
    process.env.GATSBY_PAGE_BUILDER_NEAR_NETWORK === "testnet"
      ? createShopLinkTestnet
      : createShopLinkMainnet

  const shopInput = useRef<HTMLInputElement>(null)
  const nextButton = useRef<HTMLButtonElement>(null)

  useTabCloseWarning()

  useEffect(() => {
    // Focus select
    if (shopInput.current) shopInput.current.focus()
  }, [])

  useEffect(() => {
    // Focus button after selection
    if (nextButton.current) nextButton.current.focus()
  }, [formData.shop])

  return (
    <ShopBuilderLayout
      backgroundColor="#FDFDEB"
      decoration={
        <Illustration className="max-h-[200px] max-w-[200px] md:max-h-full md:max-w-full" />
      }
    >
      <Stepper steps={4} activeStep={3} />
      <div className="flex flex-col gap-6">
        <IllustrationMobile className="md:hidden" />
        <Heading level={2}>Which shop on Mintbase are you creating?</Heading>
      </div>
      <div className="flex flex-col gap-6">
        <Combobox
          items={shops.map(({ name }) => name)}
          onChange={selectedShopName => {
            const selectedShop = shops.find(
              ({ name }) => name === selectedShopName
            )

            // If we found a matching shop, we set it. Otherwise, we reset the shop selection
            setFormData({
              ...formData,
              shop: selectedShop
                ? {
                    id: selectedShop.id,
                    name: selectedShop.name,
                  }
                : undefined,
            })
          }}
          ref={shopInput}
          disabled={shops.length === 0}
        />
        <Button
          title="Next"
          onClick={() => {
            navigate("/start-build")
          }}
          disabled={!shop}
          ref={nextButton}
        />
      </div>
      <Link href={createShopLink}>Don&apos;t have a shop yet? Create one</Link>
    </ShopBuilderLayout>
  )
}
